import { fontType, toRem } from '../../styles/functions'
import styled, { css } from 'styled-components'
import { OmitStyledComponentsDefaultProps } from '../../styles/types'
import { colors } from '../../styles/theme'

export const PlusMinusWrapper = styled.div<
	OmitStyledComponentsDefaultProps<'tertiaryColor'>
>`
	${({ styles }) => css`
		display: flex;
		align-items: center;
		justify-content: center;

		.input {
			/* Chrome, Safari, Edge, Opera */
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			/* Firefox */
			&[type='number'] {
				-moz-appearance: textfield;
			}

			background: ${styles.backgroundColor};
			border-radius: ${styles.round ? '30px' : '4px'};
			margin: 0 8px;
			padding: ${styles.padding};
			text-align: center;
			font-size: ${toRem(styles.fontSize)};
			color: ${styles.primaryColor};
			width: ${styles.fontSize * 4}px;
			border: 2px solid ${colors.grey};
		}

		.button {
			border: none;
			border-radius: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			width: ${styles.fontSize * 1.75}px;
			height: ${styles.fontSize * 1.75}px;
			cursor: pointer;

			background: ${styles.primaryColor};
			color: ${styles.secondaryColor};

			&[disabled] {
				background: ${colors.grey};
				cursor: not-allowed;
			}
		}
	`}
`
