export const colors = {
	lightGrey: '#F5F5F5',
	grey: '#D1D1D1',
	darkGrey: '#444444',
	white: '#FFFFFF',
	modalBackground: '#0000008F',
	pageBackground: '#F9F9F9',
	picpayGreen: '#21C25E',
	facebook: '#4c69ba'
}
