import styled, { css, DefaultTheme, StyledComponent } from 'styled-components'
import { toRem, transition } from '../../styles/functions'
import { colors } from '../../styles/theme'

import { IModalStyledProps } from './types'

export const ModalPrimaryWrapper = styled.div<IModalStyledProps>`
	${({ size }) => css`
		background: ${colors.modalBackground};
		position: fixed;
		top: -200px;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		padding: 16px;
		overflow: auto;
		padding-top: 64px;
		padding-bottom: 64px;

		${transition()}

		.modal-wrapper {
			padding: 32px;
			background: ${colors.white};
			max-width: ${size}px;

			border-radius: 4px;
			margin: 0 auto;

			.modal-header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 32px;
				.modal-title {
					font-size: ${toRem(18)};
					color: ${colors.darkGrey};
				}

				.modal-close {
					color: ${colors.darkGrey};
					width: 20px;
					height: 20px;
					cursor: pointer;
				}
			}
		}

		&.active {
			opacity: 1;
			top: 0;
			z-index: 999;
			pointer-events: unset;
			visibility: visible;
		}
	`}
`
