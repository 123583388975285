import { colors } from '../../styles/theme'
import { FloatLabelInputWrapper } from './styles'
import { IFloatLabelInputProps } from './types'

import React from 'react'
import { paddingToString } from '../../styles/functions'

function FloatLabelInput({
	label,
	id,
	className,
	primaryColor = colors.darkGrey,
	backgroundColor = 'transparent',
	round = false,
	secondaryColor = colors.grey,
	fontSize = 16,
	fontFamily = 'sans-serif',
	padding = [26, 16, 10, 16],
	dashedWhenDisabled = true,
	...rest
}: IFloatLabelInputProps) {
	const paddingString = paddingToString(padding)

	const mergedStyles = {
		primaryColor,
		backgroundColor,
		round,
		secondaryColor,
		fontSize,
		fontFamily,
		padding: paddingString,
		dashedWhenDisabled
	}

	return (
		<FloatLabelInputWrapper styles={mergedStyles} className={className}>
			<input className='fli-input' {...rest} id={id} required />
			<label className='fli-label' htmlFor={id}>
				{label}
			</label>
		</FloatLabelInputWrapper>
	)
}

export default FloatLabelInput
