import { fontType, toRem, transition } from '../../styles/functions'
import styled, { css } from 'styled-components'
import {
	OmitStyledComponentsDefaultProps,
	StyledComponentsDefaultProps
} from '../../styles/types'

export const ButtonWrapper = styled.div<
	OmitStyledComponentsDefaultProps<'tertiaryColor'>
>`
	${({ styles }) => css`
		button {
			background: ${styles.backgroundColor};
			color: ${styles.primaryColor};
			border: none;
			border-radius: ${styles.round ? '30px' : '4px'};
			padding: ${styles.padding};
			font-size: ${toRem(styles.fontSize)};

			cursor: pointer;
			${fontType(styles.fontFamily)};
			${transition()};

			&:hover {
				opacity: 0.8;
			}

			&:active {
				transform: translateY(1px);
				${transition(0)}
			}

			&[disabled] {
				background: ${styles.secondaryColor};
				cursor: not-allowed;
				opacity: 1;
			}
		}
	`}
`
