import { MONEY_REGEX } from './regexp'

export function toMoney(value: number): string {
	if (isNaN(value)) return ''

	// According to MDN and Node documentation itself, Node.js support only en-US locale
	// https://stackoverflow.com/questions/60011347/intl-numberformat-doesnt-convert-to-pt-br-locale
	return MONEY_FORMATTER.format(value)
}

export function toNumber(value: string): number {
	return Number(value.replace(MONEY_REGEX, '')) / 100
}

export const MONEY_FORMATTER = new Intl.NumberFormat('pt-BR', {
	style: 'currency',
	currency: 'BRL'
})
