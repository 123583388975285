import { transition } from '../../styles/functions'
import styled, { css } from 'styled-components'

import { OmitStyledComponentsDefaultProps } from '../../styles/types'
import { CheckBoxOmitedProps } from './types'

export const CheckBoxPrimaryWrapper = styled.label<
	OmitStyledComponentsDefaultProps<CheckBoxOmitedProps>
>`
	${({ styles }) => css`
		position: relative;
		width: ${styles.fontSize}px;
		height: ${styles.fontSize}px;
		cursor: pointer;
		display: block;

		.checkmark {
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			width: ${styles.fontSize}px;
			height: ${styles.fontSize}px;
			padding: ${styles.padding};
			border-radius: ${styles.round ? '100%' : ''};
			background: ${styles.backgroundColor};
			border: 1px solid ${styles.primaryColor};
			color: transparent;

			${transition()}
		}

		.checkbox-input {
			position: absolute;
			opacity: 0;
			height: 0;
			width: 0;

			&:checked {
				+ .checkmark {
					background: ${styles.secondaryColor};
					border: 1px solid transparent;
					color: ${styles.primaryColor};
				}
			}

			&[disabled] {
				+ .checkmark {
					border: 1px dashed ${styles.secondaryColor};
				}
			}
		}
	`}
`
