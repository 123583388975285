import 'react-activity/dist/react-activity.css'
import {
	Bounce,
	Digital,
	Dots,
	Levels,
	Sentry,
	Spinner,
	Squares,
	Windmill
} from 'react-activity'
import { LoadingWrapper } from './styles'
import React from 'react'
import { ILoadingProps, LoadingTypeEnum } from './types'
import { colors } from '../../styles/theme'

function renderLoading(
	type: LoadingTypeEnum,
	size: number,
	primaryColor: string
) {
	switch (type) {
		case 'Bounce':
			return (
				<Bounce size={size} color={primaryColor} speed={1} animation={true} />
			)

		case 'Digital':
			return (
				<Digital size={size} color={primaryColor} speed={1} animation={true} />
			)
		case 'Dots':
			return (
				<Dots size={size} color={primaryColor} speed={1} animation={true} />
			)
		case 'Levels':
			return (
				<Levels size={size} color={primaryColor} speed={1} animation={true} />
			)
		case 'Sentry':
			return (
				<Sentry size={size} color={primaryColor} speed={1} animation={true} />
			)
		case 'Spinner':
			return (
				<Spinner size={size} color={primaryColor} speed={1} animation={true} />
			)

		case 'Squares':
			return (
				<Squares size={size} color={primaryColor} speed={1} animation={true} />
			)

		case 'Windmill':
			return (
				<Windmill size={size} color={primaryColor} speed={1} animation={true} />
			)
	}
}

function Loading({
	primaryColor = colors.darkGrey,
	size = 24,
	type
}: ILoadingProps) {
	return (
		<LoadingWrapper>{renderLoading(type, size, primaryColor)}</LoadingWrapper>
	)
}

export default Loading
