import styled, { css } from 'styled-components'
import { transition } from '../../styles/functions'
import { PickStyledComponentsDefaultProps } from '../../styles/types'
import { MenuIconPickedProps } from './types'

const lineStyle = `
display: block;
height: 1px;
width: 20px;
margin-bottom: 5px;
position: relative;
${transition()}

&:last-of-type {
	margin-bottom: 0;
}
`

export const MenuIconWrapper = styled.div<
	PickStyledComponentsDefaultProps<MenuIconPickedProps>
>`
	${({ styles }) => css`

	.menu-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 80px;
		height: 80px;
		.menu {
			width: 24px;
			height: 24px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			cursor: pointer;
			${transition()}

			.line01 {
				${lineStyle}
				background: ${styles.primaryColor};
			}
			.line02 {
				${lineStyle}
				background: ${styles.primaryColor};
			}
			.line03 {
				${lineStyle}
				background: ${styles.primaryColor};
			}
		}
	}

	&.active {
		.menu-wrapper {
			.menu {
				transform: rotate(-180deg);
				.line01 {
					transform: rotate(-45deg);
					top: 6px;
				}
				.line02 {
					transform: rotate(45deg);
				}
				.line03 {
					transform: rotate(-60deg);
					opacity: 0;
				}
			}
		}
	}
`}
`
