import { VALID_DDDS } from './util/constants'
import { reverseDate } from './util/date'
import {
	formatCNPJ,
	formatCPF,
	formatCnpjCpf,
	formatPhone,
	formatPostalCode,
	formatRG
} from './util/mask'
import { toMoney, toNumber } from './util/money'
import { deepCopy } from './util/object'
import {
	ALL_SAME_NUMBER,
	IS_VALID_EMAIL,
	IS_VALID_PASSWORD,
	MONEY_REGEX,
	NON_DIGIT,
	NON_LETTER_AND_DIGIT,
	SPECIAL_CHAR_AND_WHITESPACE
} from './util/regexp'
import { include } from './util/string'
import {
	checkCNPJ,
	checkCPF,
	checkCPFCNPJ,
	checkEmail,
	checkEmailMatch,
	checkPassword,
	checkPasswordMatch,
	checkPostalCode,
	checkRG,
	checkTel,
	checkString
} from './util/validators'
import { generateRandomSerial } from './util/generators'

// *******

export const UtilConstants = { VALID_DDDS }
export const UtilDate = { reverseDate }
export const UtilMask = {
	formatCNPJ,
	formatCPF,
	formatCnpjCpf,
	formatPhone,
	formatPostalCode,
	formatRG
}
export const UtilMoney = { toMoney, toNumber }
export const UtilObject = { deepCopy }
export const UtilRegExp = {
	ALL_SAME_NUMBER,
	IS_VALID_EMAIL,
	IS_VALID_PASSWORD,
	MONEY_REGEX,
	NON_DIGIT,
	NON_LETTER_AND_DIGIT,
	SPECIAL_CHAR_AND_WHITESPACE
}
export const UtilString = { include }
export const UtilValidators = {
	checkCNPJ,
	checkCPF,
	checkCPFCNPJ,
	checkEmail,
	checkEmailMatch,
	checkPassword,
	checkPasswordMatch,
	checkPostalCode,
	checkRG,
	checkTel,
	checkString
}

export const UtilGenerators = {
	generateRandomSerial
}
